import React from "react"
import photosStyles from "./wonderful-photos-a6.module.css"
import ImagesSwiperA6 from './images-swiper-a6'
import PropTypes from "prop-types"

export default class WonderfulPhotosA6 extends React.Component{
  static propTypes={
    photos:PropTypes.array.isRequired
  }
  render() {
    const photos=this.props.photos
    return(
      <div className={photosStyles.photosContainer}>
        <div className={photosStyles.title}>
          <div>
            <div className={photosStyles.leftTop}></div>
            <div className={photosStyles.leftBottom}></div>
          </div>
          <div className={photosStyles.titleText}>赛事照片</div>
          <div>
            <div className={photosStyles.rightTop}></div>
            <div className={photosStyles.rightBottom}></div>
          </div>
        </div>

        <ImagesSwiperA6 imageUrls={photos}/>
      </div>
    )
  }
}