import React from "react"
import newsStyles from './news-a6.module.css'

export default class NewsA6 extends React.Component {
  render() {
    const news=["1、新闻资讯一新闻资讯一新闻资讯一","2、新闻资讯二新闻资讯二","3、新闻资讯三新闻资讯三"]
    const newslist=news.map((news,index)=><div key={index} className={newsStyles.newsItem}>
      {news}
    </div>)

    const notices=["1、新闻资讯一新闻资讯一新闻资讯一","2、新闻资讯二新闻资讯二","3、新闻资讯三新闻资讯三"]
    const noticeslist=notices.map((notice,index)=><div key={index} className={newsStyles.noticeItem}>
      {notice}
    </div>)
    return (
      <div className={newsStyles.newsContainer}>
        <div className={newsStyles.news}>
          <img className={newsStyles.icon} src={require('../images/a6/news.svg')}/>
          <div className={newsStyles.newsContent}>
            <div className={newsStyles.title}>新闻</div>
            <div className={newsStyles.newslist}>
              {newslist}
            </div>
          </div>
        </div>
        <div className={newsStyles.notices}>
          <img className={newsStyles.icon} src={require('../images/a6/notice.svg')}/>
          <div className={newsStyles.noticesContent}>
            <div className={newsStyles.title}>公告</div>
            <div className={newsStyles.noticeslist}>
              {noticeslist}
            </div>
          </div>
        </div>
      </div>
    )
  }
}