import React from "react"
import mapStyles from "./map-a6.module.css"

export default class MapA6 extends React.Component {
  render() {
    return(
      <div>
        <div className={mapStyles.title}>
          <div>
            <div className={mapStyles.leftTop}></div>
            <div className={mapStyles.leftBottom}></div>
          </div>
          <div className={mapStyles.titleText}>赛事地图</div>
          <div>
            <div className={mapStyles.rightTop}></div>
            <div className={mapStyles.rightBottom}></div>
          </div>
        </div>
        <div className={mapStyles.map}>
          <img src={require('../images/a6/temp1_map.png')}/>
          <div className={mapStyles.mapMore}>
            <div className={mapStyles.topArrow}></div>
            <div className={mapStyles.code}>
              <div className={mapStyles.codeText}>扫码查看</div>
              <img src={require('../images/a6/code.png')}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}