import React from "react"
import PropTypes from "prop-types"
import Swiper from 'swiper'
import './poster-swiper.css'


export default class PosterSwiper extends React.Component {
  static propTypes={
    imageUrls:PropTypes.array.isRequired
  }
  componentDidMount() {
    var swiper = new Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }
  render() {
    const images=this.props.imageUrls.map((image,index)=>
      <div key={index} className="swiper-slide"  style={{backgroundImage:'url('+image+')',backgroundSize:'cover',backgroundRepeat: 'no-repeat',
backgroundPosition: 'center'}}>
    </div>)
    return(
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {images}
          </div>
          <div className="swiper-pagination"></div>
        </div>
    )
  }
}