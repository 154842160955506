import React from "react"
import supportersStyles from './supporters-a6.module.css'


export default class SupportersA6 extends React.Component {
  render() {
    const supporters=[{
      name:'冠名赞助商',
      icons:[  "//cdn.chinarun.com/upload/site/1000/img/art/20170519/20170519120428274.jpg?_t_=1495195468"]
    },
      {
        name:'官方合作伙伴',
        icons:["//cdn.chinarun.com/upload/site/1132/img/art/201907/12/ce913996-b838-4572-ab03-b371294f1e87.jpg?_t_=1562949035",
          "//cdn.chinarun.com/upload/site/1000/img/art/20160721/20160721095233009.jpg?_t_=1469094753"]
      },
      {
        name:'友好合作伙伴',
        icons:[  "//cdn.chinarun.com/upload/site/1132/img/art/201910/10/ad63df0e-978e-4f0f-ae25-3e58c99db190.jpg?_t_=1570701899",]
      }]
    return(
      <div className={supportersStyles.supportersContainer}>
        {supporters.map((item,index)=><div key={index} className={supportersStyles.supporter} key={index}>
          <div className={supportersStyles.title}>
            <div>
              <div className={supportersStyles.leftTop}></div>
              <div className={supportersStyles.leftBottom}></div>
            </div>
            <div className={supportersStyles.titleText}>{item.name}</div>
            <div>
              <div className={supportersStyles.rightTop}></div>
              <div className={supportersStyles.rightBottom}></div>
            </div>
          </div>
          {/*<div className={supportersStyles.name}>*/}
          {/*  {item.name}*/}
          {/*</div>*/}
          <div className={supportersStyles.iconsContainer}>
            <div className={supportersStyles.iconsList}>
              {item.icons.map((icon,index)=><div className={supportersStyles.icon} key={index}>
                <img src={icon} alt="赞助商"/>
              </div>)}
            </div>
          </div>
        </div>)}
      </div>
    )
  }
}