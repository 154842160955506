import React from "react"
import LayoutA6 from "../../components/layout-a6"
import CountDownA6 from "../../components/countdown-a6"
import NewsA6 from '../../components/news-a6'
import homeStyles from "./a6_home.module.css"
import WonderfulPhotosA6 from '../../components/wonderful-photos-a6'
import SupportersA6 from '../../components/supporters-a6'
import PosterSwiper from '../../components/poster-swiper'
import MapA6 from '../../components/map-a6'

export default  class A6Home extends React.Component {
  render() {
    const posters=[
      '//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg'
    ]
    const photos=['//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/ed8ef306-5795-4ec4-ad65-7c405ea8c18b_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/0f901f59-365a-48cf-9bdb-745aae9784cc_900x1190.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/1a854aa1-e2f5-42f6-b27c-e94a4890d086_900x637.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/21d92fcc-5ba6-450a-8c7f-43042769f7cf_900x474.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/591f320c-cd80-4882-b8c8-22e1b9eca053_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/5d76a590-9d11-4e59-aebb-9eaaf89cf752_900x642.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/75b7f65c-e685-493a-a79d-e4796028c6dc_900x651.jpeg']
    return(
      <LayoutA6 >
        <div className={homeStyles.posters}>
          <PosterSwiper imageUrls={posters}/>
        </div>
        <CountDownA6 count={1000}/>
        <div className={homeStyles.news}>
          <NewsA6 />
          <div className={homeStyles.newsBg}></div>
        </div>
        <div className={homeStyles.map}>
          <MapA6 />
        </div>
        <div className={homeStyles.photos}>
          <div className={homeStyles.photosBg}></div>
          <WonderfulPhotosA6 photos={photos}/>
        </div>
        <div className={homeStyles.supporters}>
          <SupportersA6 />
        </div>

      </LayoutA6>
      )

  }
}