import React from 'react'
import PropTypes from "prop-types"
import countdownStyles from "./countdown-a6.module.css"

export default class CountDownA6 extends React.Component{
  static propTypes={
    count:PropTypes.number.isRequired
  }
  render() {
    let days=Math.floor(this.props.count / 60 % 60)
    let hours=Math.floor(this.props.count / 60 % 60)
    let minutes = Math.floor(this.props.count / 60 % 60)
    let seconds = Math.floor(this.props.count % 60)
    return(
      <div className={countdownStyles.countDownContainer}>
        <div className={countdownStyles.title}>赛事倒计时</div>
        <div className={countdownStyles.timeContainer}>
          <div className={countdownStyles.day}>
            <div className={countdownStyles.time}>{days}</div>
            <div className={countdownStyles.text}>天</div>
          </div>
          <div className={countdownStyles.colon}>:</div>
          <div className={countdownStyles.hour}>
            <div className={countdownStyles.time}>{hours}</div>
            <div className={countdownStyles.text}>时</div>
          </div>
          <div className={countdownStyles.colon}>:</div>
          <div className={countdownStyles.day}>
            <div className={countdownStyles.time}>{minutes}</div>
            <div className={countdownStyles.text}>分</div>
          </div>
          <div className={countdownStyles.colon}>:</div>
          <div className={countdownStyles.day}>
            <div className={countdownStyles.time}>{seconds}</div>
            <div className={countdownStyles.text}>秒</div>
          </div>
        </div>
      </div>
    )
  }
}